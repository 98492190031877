// 2020-06-12
import React, { useState } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { useEffect } from "react";
import styled from "@emotion/styled";

const ClickTarget = styled.div`
  user-select: none;
  border: 1px solid black;
  padding: 10px;
`;

const AutoClicker = () => {
  const [clicks, setClicks] = useState(0);
  useEffect(() => {
    const body = document.querySelector("body");
    const listener = () => {
      setClicks(clix => clix + 1);
      setTimeout(() => setClicks(clix => clix - 1), 1000);
    };
    body.addEventListener("click", listener);
    return () => body.removeEventListener("click", listener);
  }, []);
  const [maxClicks, setMaxClicks] = useState(0);
  useEffect(() => {
    if (clicks > maxClicks) {
      setMaxClicks(clicks);
    }
  }, [clicks, maxClicks]);

  return (
    <Layout>
      <SEO title="Auto Clicker"></SEO>
      <ClickTarget>
        <p>How fast can you click? Click within this box!</p>

        <p>Clicks per second: {clicks}</p>
        <p>Max clicks per second: {maxClicks}</p>
      </ClickTarget>
    </Layout>
  );
};

export default AutoClicker;
